<template>
  <div :class="rootClasses">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'
import { useDialog } from '#imports'

export default {
  name: 'HbDialogFooter',
  props: {
    noBorder: { type: Boolean, default: () => false },
  },
  setup(props) {
    const dialog = useDialog(props)
    const rootClasses = computed(() => ({
      'hb-dialog__footer': true,
      'hb-dialog__footer--no-border': props.noBorder,
      'hb-dialog__footer--has-actions': dialog.value.hasActions.value,
    }))

    return {
      rootClasses,
      dialog,
    }
  },
}
</script>

<style lang="scss">
.hb-dialog__footer {
  --hb-dialog-footer-x-padding: var(--hb-dialog-x-padding);
  --hb-dialog-footer-y-padding: 40px;

  border-top: 1px var(--hb-gray2) solid;
  padding: var(--hb-dialog-footer-y-padding) var(--hb-dialog-footer-x-padding);
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  > * {
    flex: 1;
  }

  &--no-border {
    border-top: unset;
    padding-top: 15px;
  }

  &--has-actions {
    @include mobile-md {
      padding: 0;
      border-top: 0;
    }
  }

  @include tablet {
    .button-wrapper {
      flex-direction: column-reverse;
    }
  }
}
</style>
